import React from 'react';
import '../sass/Footer.scss';

const Footer = () => {
  return (
    <footer>
        mariacontreraspsicologa.com
      <br />
        2023 · web by <a href="https://trivify.es/">trivify.es</a>
    </footer>
  );
};

export default Footer;